import { useState } from "react"
import { Button, ButtonLink } from "src/components/Button"
import { LAYOUT } from "src/constants"
import { AssociationFilters } from "src/constants/filters"
import { getSearchPath } from "src/helpers/slug"
import { capitalize } from "src/helpers/text"
import styled from "styled-components"

const LIMIT = 8

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  a,
  button {
    margin: 0 5px 10px;
  }
  @media (min-width: ${LAYOUT.tablet}px) {
    justify-content: center;
  }
`

interface Props extends Omit<AssociationFilters, "tagId" | "type"> {
  list: DomainTagCount[]
}

const Tags = ({ list, ...filters }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const limited = !expanded && list.length > LIMIT

  const tagCounts = limited ? list.slice(0, LIMIT) : list

  return (
    <Container>
      {tagCounts.map(({ tag }) => (
        <ButtonLink key={tag.id} $empty to={getSearchPath({ ...filters, tagIds: [tag.id] })}>
          {capitalize(tag.name)}
        </ButtonLink>
      ))}
      {limited && <Button onClick={() => setExpanded(true)}>Plus de filtres</Button>}
    </Container>
  )
}

export default Tags
