import { graphql, Link, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { useState } from "react"
import { Button } from "src/components/Button"
import { Block, Section } from "src/components/Grid"
import { LAYOUT, SIZES } from "src/constants"
import { getHighlightPath } from "src/helpers/slug"
import styled from "styled-components"

const LIMITED_COUNT = 3
const IMAGE_RATIO = 1.8

const HomeSection = styled(Section)`
  padding-top: 10px;
  padding-bottom: 40px;
`
const Highlight = styled(Link)`
  h4 {
    font-size: ${SIZES.medium}px;
    margin: 0.8em 0 0;
  }
  .gatsby-image-wrapper {
    border-radius: 3px;
  }
`
const Desktop = styled.div`
  text-align: center;
  margin-top: 20px;
  @media (max-width: ${LAYOUT.width - 1}px) {
    display: none;
  }
`
const LimitedGrid = styled.div<{ $limited?: boolean }>`
  display: flex;
  margin: 0 -10px;
  overflow: auto;
  @media (min-width: ${LAYOUT.width + 1}px) {
    flex-wrap: wrap;
    ${({ $limited }) => ($limited ? `> div:nth-child(1n+${LIMITED_COUNT + 1}) { display: none; }` : "")}
  }
`

const HIGHLIGHTS_QUERY = graphql`
  query {
    assolib {
      allDomainHighlights(disabled: false) {
        id
        name
        preview_img_url # needed for preview_image to work!
        preview_image {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

interface Data {
  assolib: {
    allDomainHighlights: DomainHighlight[]
  }
}

const Selection = () => {
  const { assolib } = useStaticQuery<Data>(HIGHLIGHTS_QUERY)

  const [expanded, setExpanded] = useState(false)

  const limited = !expanded && assolib.allDomainHighlights.length > LIMITED_COUNT

  return (
    <HomeSection>
      <h2>Notre sélection</h2>
      <LimitedGrid $limited={limited}>
        {assolib.allDomainHighlights.map((highlight) => (
          <Block key={highlight.id} $col={3}>
            <Highlight to={getHighlightPath(highlight)}>
              <Image
                fluid={{ ...highlight.preview_image.childImageSharp.fluid, aspectRatio: IMAGE_RATIO }}
                loading="eager"
              />
              <h4>{highlight.name}</h4>
            </Highlight>
          </Block>
        ))}
      </LimitedGrid>
      {limited && (
        <Desktop>
          <Button onClick={() => setExpanded(true)}>Voir plus</Button>
        </Desktop>
      )}
    </HomeSection>
  )
}

export default Selection
