import { gql } from "@apollo/client"
import { useEffect, useRef, useState } from "react"
import { Box } from "src/components/SearchInput"
import { COLORS, SIZES } from "src/constants"
import { useClientQuery } from "src/helpers/apollo"
import { capitalize } from "src/helpers/text"
import CloseIcon from "src/svg/close.svg"
import SearchIcon from "src/svg/search.svg"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

export const Container = styled.div`
  position: relative;
`
export const Dropdown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 50px;
  background-color: ${COLORS.white};
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
`
export const Option = styled.button`
  border: none;
  display: block;
  width: 100%;
  text-align: left;
  background-color: transparent;
  padding: 12px 20px;
  font-size: ${SIZES.medium}px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLORS.bright};
  }
  &:hover {
    background-color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor}33;
  }
`

const AUTOCOMPLETE = gql`
  query($query: String!, $tagTypes: [TagType!]) {
    allTags(q: $query, perPage: 5, filter: { types: $tagTypes }, visible: true) {
      id
      name
    }
  }
`

interface Props {
  placeholder: string
  tagTypes: TagType[]
  value?: Tag | null
  onChange: (value: Tag | null) => void
  onSuggestionsAvailable?: (values: Tag[]) => void
}

interface Results {
  allTags: Tag[]
}

export const TagAutocomplete = ({ tagTypes, placeholder, value, onChange, onSuggestionsAvailable }: Props) => {
  const inputRef = useRef<HTMLInputElement>()
  const [focus, setFocus] = useState(false)
  const [text, setText] = useState(value ? value.name : "")

  const query = text.trim()

  const { data } = useClientQuery<Results>(AUTOCOMPLETE, focus && query ? { query, tagTypes } : null)
  const suggestedTags = data?.allTags

  const handleChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    const typed = currentTarget.value
    setText(typed)
    if (value) {
      onChange(null)
    }
  }

  const handleCancel = () => {
    setFocus(false)
    setText("")
    if (value) {
      onChange(null)
      onSuggestionsAvailable([])
    }
  }

  useEffect(() => {
    if (suggestedTags) {
      onSuggestionsAvailable(suggestedTags)
    }
  }, [onSuggestionsAvailable, suggestedTags])

  useEffect(() => {
    if (value) {
      setText(capitalize(value.name))
    }
  }, [value])

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault()
        if (suggestedTags?.length > 0) {
          onChange(suggestedTags[0])
        }
      }
    }
    inputRef.current.addEventListener("keydown", keyDownHandler)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      inputRef.current?.removeEventListener("keydown", keyDownHandler)
    }
  }, [onChange, suggestedTags])

  return (
    <Container>
      <Box>
        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={text}
          onFocus={() => setFocus(true)}
          onChange={handleChange}
          onBlur={() => setTimeout(setFocus, 200, false)}
          aria-label={placeholder}
        />
        <button disabled={!text} onClick={handleCancel} aria-label={text ? "Effacer" : null} aria-hidden={!text}>
          {text ? <CloseIcon /> : <SearchIcon />}
        </button>
      </Box>
      {!value && focus && suggestedTags && (
        <Dropdown>
          {suggestedTags.map((tag) => (
            <Option key={tag.id} onClick={() => onChange(tag)}>
              {capitalize(tag.name)}
            </Option>
          ))}
        </Dropdown>
      )}
    </Container>
  )
}
