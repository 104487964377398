import { useLocation } from "@reach/router"
import { graphql, navigate, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { Button } from "src/components/Button"
import { Section } from "src/components/Grid"
import ModalSelect from "src/components/ModalSelect"
import { TagAutocomplete } from "src/components/TagAutocomplete"
import { COLORS, LAYOUT } from "src/constants"
import { AssociationFilters, DAYS, FilterData, Option } from "src/constants/filters"
import { usePropsState } from "src/helpers/hooks"
import { getSearchPath } from "src/helpers/slug"
import styled, { DefaultTheme, ThemeProps } from "styled-components"

export const Container = styled.section`
  background-image: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerBgUrl};
  background-color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.primaryColor};
  background-size: cover;
  text-align: center;
  padding: 2.5em 0;
  h1 {
    font-size: 3em;
    font-weight: bold;
    line-height: 1em;
    margin: 0 20px;
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerFgColor};
  }
  h2 {
    font-size: 2em;
    font-weight: 100;
    margin: 10px 20px;
    color: ${(prop: ThemeProps<DefaultTheme>) => prop.theme.headerFgColor};
  }
  @media (max-width: ${LAYOUT.tablet - 1}px) {
    padding: 2em 0 1em;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
  }
`
export const Content = styled(Section)`
  padding: 10px;
  > div,
  > button {
    margin: 10px;
  }
  @media (min-width: ${LAYOUT.tablet}px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 30px auto 10px;
    > div {
      flex: 1;
      max-width: 300px;
    }
  }
`
export const FindButton = styled(Button)`
  padding: 14px 20px;
  &:hover {
    background-color: ${COLORS.grey};
    border-color: ${COLORS.grey};
  }
`

const DATA_QUERY = graphql`
  query {
    assolib {
      allDomains {
        home_title
        home_subtitle
      }
      allTags(types: [MAIN, NORMAL]) {
        id
        name
      }
      allAgeSubGroups(sortField: "id", sortOrder: DESC) {
        id
        name
      }
      allScheduleGroups(sortField: "id", sortOrder: DESC) {
        name
        id
      }
    }
  }
`

interface Data {
  assolib: {
    allDomains: Domain[]
    allTags: Tag[]
    allAgeSubGroups: AgeSubGroup[]
    allScheduleGroups: ScheduleGroup[]
  }
}

const missionTagTypes: TagType[] = ["MAIN", "NORMAL"]

export const ActivityFiltersSection = ({ tagId, ageIds, dayIds, scheduleIds }: Omit<AssociationFilters, "type">) => {
  const { assolib } = useStaticQuery<Data>(DATA_QUERY)

  const AGES: FilterData = {
    options: assolib.allAgeSubGroups,
  }
  const SCHEDULES: FilterData = {
    title: "Créneau(x) de la journée",
    options: assolib.allScheduleGroups,
  }

  const getInitialTag = () => tagId && assolib.allTags.find(({ id }) => id === tagId)
  const getInitialAges = () => (ageIds ? AGES.options.filter(({ id }) => ageIds.includes(id)) : [])
  const getInitialDays = () => (dayIds ? DAYS.options.filter(({ id }) => dayIds.includes(id)) : [])
  const getInitialSchedules = () => (scheduleIds ? SCHEDULES.options.filter(({ id }) => scheduleIds.includes(id)) : [])

  const [tag, setTag] = usePropsState<Tag>(getInitialTag, tagId)
  const [suggestedTags, setSuggestedTags] = usePropsState<Tag[]>(
    () => (getInitialTag() ? [getInitialTag()] : undefined),
    tagId
  )
  const [ages, setAges] = usePropsState<Option[]>(getInitialAges, ageIds)
  const [days, setDays] = usePropsState<Option[]>(getInitialDays, dayIds)
  const [schedules, setSchedules] = usePropsState<Option[]>(getInitialSchedules, scheduleIds)

  const destination = getSearchPath({
    tagIds: tag ? [tag.id] : suggestedTags?.length > 0 ? [suggestedTags[0].id] : undefined,
    ageIds: ages.map(({ id }) => id),
    dayIds: days.map(({ id }) => id),
    scheduleIds: schedules.map(({ id }) => id),
  })

  const location = useLocation()
  const current = location.pathname + location.search

  useEffect(
    () => {
      // TODO This break the single-responsibility principle. This component should not be aware and deal with paths.
      if (destination === current) {
        return
      }

      // TODO This break the single-responsibility principle. This component should not be aware of its parent.
      if (current.startsWith("/selection/")) {
        const initialPath = getSearchPath({
          tagIds: tagId ? [tagId] : undefined,
          ageIds: getInitialAges().map(({ id }) => id),
          dayIds: getInitialDays().map(({ id }) => id),
          scheduleIds: getInitialSchedules().map(({ id }) => id),
        })
        if (destination === initialPath) {
          return
        }
      }

      if (destination !== "/search/" || /^\/(tag|search)\//.test(current)) {
        navigate(destination)
      }
    },
    // We should include `destination` in the deps, but we don't want suggestedTags to trigger anything.
    // This whole useEffect should not exist in the first place, so we deem it acceptable for now.
    [tag, ages, days, schedules] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleSearch = () => {
    if (current !== destination) {
      navigate(destination)
    }
  }

  const domain = assolib.allDomains[0]

  return (
    <Container>
      <h1>{domain.home_title}</h1>
      <h2>{domain.home_subtitle}</h2>
      <Content as="div">
        <TagAutocomplete
          placeholder="Recherche une activité"
          tagTypes={missionTagTypes}
          value={tag}
          onChange={setTag}
          onSuggestionsAvailable={setSuggestedTags}
        />
        <ModalSelect
          placeholder="Pour tous les âges"
          title="Je sélectionne une tranche d’âge"
          data={[AGES]}
          values={[ages]}
          onChange={[setAges]}
        />
        <ModalSelect
          placeholder="Toute la semaine"
          title="Je sélectionne des créneaux horaires"
          data={[DAYS, SCHEDULES]}
          values={[days, schedules]}
          onChange={[setDays, setSchedules]}
        />
        <FindButton onClick={handleSearch}>Trouver</FindButton>
      </Content>
    </Container>
  )
}
