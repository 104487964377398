import { COLORS, SIZES } from "src/constants"
import CloseIcon from "src/svg/close.svg"
import SearchIcon from "src/svg/search.svg"
import styled from "styled-components"

export const Box = styled.div`
  background-color: ${COLORS.white};
  border-radius: 25px;
  border: 1px solid ${COLORS.bright};
  position: relative;
  z-index: 2;
  display: flex;
  overflow: hidden;
  input {
    font-size: ${SIZES.medium}px;
    padding: 15px 20px;
    flex: 1;
    width: 100%;
    border: none;
    &::placeholder {
      color: ${COLORS.greyDark};
    }
  }
  button {
    width: 50px;
    line-height: 0;
    svg {
      vertical-align: middle;
    }
  }
`

interface Props {
  value?: string
  onChange: (value: string) => void
}

const SearchInput = ({ value, onChange }: Props) => {
  const handleChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(currentTarget.value)
  }

  const handleCancel = () => {
    onChange("")
  }

  return (
    <Box>
      <input
        type="text"
        placeholder="Nom de l’association"
        value={value}
        onChange={handleChange}
        aria-label="Rechercher un domaine"
      />
      <button
        disabled={!value}
        onClick={handleCancel}
        aria-label={value ? "Effacer" : null}
        aria-hidden={!value}
        type="button"
      >
        {value ? <CloseIcon /> : <SearchIcon />}
      </button>
    </Box>
  )
}

export default SearchInput
